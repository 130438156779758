import React from "react";
import Layout from "../components/Layout";

const Internal = props => {
  const location = props.location;
  const pageTitle = "Internal View";
  const pageSlug = "internal-view";

  return (
    <Layout location={location} pageSlug={pageSlug} pageTitle={pageTitle}>
      <div className="container-md">
        <h1>{pageTitle}</h1>
      </div>
    </Layout>
  );
};

export default Internal;
